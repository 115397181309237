import React, { FC } from 'react';

import * as styles from './bolagsStyrningsCard.module.css';

type BodyTextProps = {
  headline: string;
  subtitle: string;
  bodyText: { childMarkdownRemark: { html: string } };
};

const BolagsStyrningsCard: FC<BodyTextProps> = ({
  bodyText,
  headline,
  subtitle,
}) => {
  return (
    <div className={styles.wrapper}>
      <h3>{headline}</h3>
      <p>{subtitle}</p>
      <div
        className={styles.bodyText}
        dangerouslySetInnerHTML={{
          __html: bodyText?.childMarkdownRemark?.html,
        }}
      />
    </div>
  );
};

export default BolagsStyrningsCard;
